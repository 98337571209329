import Api from 'utils/Api'

export function getDesignIdeas(){
   return Api
        .get(`designideas/designideas/design_idea_categories/`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        }) 
}

export function getDesignIdeasByTheme(theme_id){
    return Api
        .get(`designideas/designideas/design_idea_by_theme/?theme_id=${theme_id}`)
        .then(response =>{
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getDesignIdeasByRoom(room_id){
    return Api
        .get(`designideas/designideas/design_idea_by_room/?room_id=${room_id}`)
        .then(response =>{
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getDesignIdeasByMood(){
    return Api
        .get(`v2/usermanagement/my_unit_design`)
        .then(response =>{
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getInspirationalIdeas(){
    return Api
        .get(`designideas/designideas/looking_for_inspiration/`)
        .then(response =>{
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
