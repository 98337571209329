import { getDesignIdeasByMood } from 'api/designIdeasApi'
import { getUserData, getProject, getDraft } from 'api/userApi'


const MudiPage = {
    state: () => ({
        loading: false,
        designIdeas: [],
        profileData: [],
        projectData: [],
        draft: {},
    }),

    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setDesignIdeas(state, payload) {
            state.designIdeas = payload
        },
        setProfileData(state, payload) {
            state.profileData = payload
        },
        setProjectData(state, payload) {
            state.projectData = payload
        },

        setDraft(state, payload) {
            state.draft = payload
        },
    },

    getters: {},

    actions: {
        fetchDesignIdeas({ commit }) {
            return getDesignIdeasByMood()
                .then(response => {
                    if (response.responseCode != 200) {
                        console.log('fetching failed')
                    } else {
                        commit('setDesignIdeas', response.payload)
                        return response.payload
                    }
                })
        },


        fetchUserData({ commit }) {
            return getUserData().then(response => {
                if (response.responseCode != 200) {
                    console.log('fetching failed')
                } else {
                    commit('setProfileData', response.payload)
                    return response.payload
                }
            })
        },


        fetchProject({ commit }) {
            return getProject().then(response => {
                if (response.responseCode != 200) {
                    console.log('fetching failed')
                } else {
                    commit('setProjectData', response.payload)
                    return response
                }
            })
        },

        fetchDraft({ commit }) {
            return getDraft().then(response => {
                if (response.responseCode != 200) {
                    console.log('fetching failed')
                } else {
                    commit('setDraft', response.payload)
                    return response
                }
            })
        },

    }
}

export default MudiPage;