<template>
    <div>
        <div class="mudi-page">
            <div class="sidebar">
                <ul class="sidebar-list">
                    <router-link
                        :to="{
                            name: 'MyWorkspace',
                        }"
                        active-class="active"
                        class="menu"
                    >
                        <div class="menu-container">
                            <div class="link-icon">
                                <div v-if="$route.path === '/mudi/workspace'">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="23.453"
                                        height="22.617"
                                    >
                                        <g
                                            id="noun_Suitcase_4083503"
                                            transform="translate(-35 -42.757)"
                                        >
                                            <path
                                                id="Rectangle_1069"
                                                data-name="Rectangle 1069"
                                                class="cls-1"
                                                transform="translate(39.669 61.66)"
                                                d="M0 0h3.449v1.114H0z"
                                            />
                                            <path
                                                id="Rectangle_1070"
                                                data-name="Rectangle 1070"
                                                class="cls-1"
                                                transform="translate(50.335 61.66)"
                                                d="M0 0h3.449v1.114H0z"
                                            />
                                            <path
                                                id="Path_961"
                                                data-name="Path 961"
                                                class="cls-1"
                                                d="M140.459 318H139.3a.306.306 0 0 0-.3.294v3.473h1.751v-3.473a.3.3 0 0 0-.292-.294z"
                                                transform="translate(-98.482 -260.638)"
                                            />
                                            <path
                                                id="Path_962"
                                                data-name="Path 962"
                                                class="cls-1"
                                                d="M36.86 365.784h19.733a1.851 1.851 0 0 0 1.86-1.845V360h-4.988v1.539h.578a.279.279 0 0 1 .271.276v1.648a.259.259 0 0 1-.271.252h-3.977a.251.251 0 0 1-.262-.252v-1.648a.272.272 0 0 1 .262-.276h.587V360H42.8v1.539h.587a.272.272 0 0 1 .262.276v1.648a.251.251 0 0 1-.262.252h-3.978a.258.258 0 0 1-.271-.252v-1.648a.279.279 0 0 1 .271-.276h.578V360H35v3.939a1.851 1.851 0 0 0 1.86 1.845z"
                                                transform="translate(0 -300.409)"
                                            />
                                            <path
                                                id="Path_963"
                                                data-name="Path 963"
                                                class="cls-1"
                                                d="M141.218 45.789a.606.606 0 0 1 .289-.5 8.664 8.664 0 0 1 4.661-1.467 8.5 8.5 0 0 1 4.636 1.5.6.6 0 0 1 .284.5v.878h1.22v-.985a.836.836 0 0 0-.07-.352.828.828 0 0 0-.181-.265 8.645 8.645 0 0 0-11.8-.041.83.83 0 0 0-.184.26.849.849 0 0 0-.073.358v.985h1.22z"
                                                transform="translate(-99.427)"
                                            />
                                            <path
                                                id="Path_964"
                                                data-name="Path 964"
                                                class="cls-1"
                                                d="M326.11 127a.29.29 0 0 0-.287.29v.665h3.689v-.655a.289.289 0 0 0-.287-.289l-3.114-.011z"
                                                transform="translate(-275.39 -79.773)"
                                            />
                                            <path
                                                id="Path_965"
                                                data-name="Path 965"
                                                class="cls-1"
                                                d="M341.45 318h-1.158a.3.3 0 0 0-.292.294v3.473h1.751v-3.473a.306.306 0 0 0-.301-.294z"
                                                transform="translate(-288.816 -260.638)"
                                            />
                                            <path
                                                id="Path_966"
                                                data-name="Path 966"
                                                class="cls-1"
                                                d="M120.336 126.311a.292.292 0 0 0-.287-.292l-3.113-.019a.3.3 0 0 0-.2.093.308.308 0 0 0-.085.213v.7h3.689z"
                                                transform="translate(-77.314 -78.823)"
                                            />
                                            <path
                                                id="Path_967"
                                                data-name="Path 967"
                                                class="cls-1"
                                                d="M39.988 163.943a.836.836 0 0 1 .831-.825h1.158a.829.829 0 0 1 .823.825v1.4h7.853v-1.4a.829.829 0 0 1 .823-.825h1.158a.836.836 0 0 1 .831.825v1.4h4.988v-8.489a1.862 1.862 0 0 0-1.86-1.854H36.86a1.862 1.862 0 0 0-1.86 1.858v8.489h4.988zm12.416-5.7a.265.265 0 0 1 .531 0v1.893a.265.265 0 0 1-.531 0zm-1.963 0a.265.265 0 0 1 .531 0v1.893a.265.265 0 0 1-.531 0zm-2.016 0a.265.265 0 0 1 .531 0v1.893a.265.265 0 0 1-.531 0zm-1.963 0a.265.265 0 0 1 .531 0v1.893a.265.265 0 0 1-.531 0zm-2.016 0a.265.265 0 0 1 .531 0v1.893a.265.265 0 0 1-.531 0zm-1.963 0a.265.265 0 0 1 .531 0v1.893a.265.265 0 0 1-.531 0zm-2.016 0a.265.265 0 0 1 .531 0v1.893a.265.265 0 0 1-.531 0zm-3.927 4.627a.265.265 0 1 1-.531 0v-4.726a.265.265 0 1 1 .531 0zm0-5.665a.265.265 0 0 1-.531 0v-.284a.265.265 0 0 1 .531 0z"
                                                transform="translate(0 -106.287)"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <div v-else>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="mudiicons"
                                        width="23.425"
                                        height="22.617"
                                    >
                                        <defs></defs>
                                        <g
                                            id="noun_Suitcase_4082700"
                                            transform="translate(-7.713 -32)"
                                        >
                                            <path
                                                id="Path_968"
                                                data-name="Path 968"
                                                d="M26.325 54.617H45.1a2.317 2.317 0 0 0 2.325-2.31V39.978a2.328 2.328 0 0 0-2.325-2.324h-1.8v-.569a.835.835 0 0 0-.832-.831h-.347v-.889a1.355 1.355 0 0 0-.116-.565v-.013a1.316 1.316 0 0 0-.293-.405A8.911 8.911 0 0 0 35.724 32h-.029a8.982 8.982 0 0 0-5.977 2.339 1.312 1.312 0 0 0-.3.415 1.351 1.351 0 0 0-.121.572v.884h-.34a.832.832 0 0 0-.837.827v.618h-1.8A2.328 2.328 0 0 0 24 39.978v12.329a2.317 2.317 0 0 0 2.325 2.31zm16.15-17.757a.224.224 0 0 1 .222.224v.57h-3.409v-.588a.22.22 0 0 1 .224-.218zm-10.568-.64h-.335v-.791h.015a7.547 7.547 0 0 1 4.108-1.3h.023a7.53 7.53 0 0 1 4.117 1.332h.016v.789h-.326a.827.827 0 0 0-.592.24.813.813 0 0 0-.247.581v.589h-5.944v-.6a.839.839 0 0 0-.835-.84zm-2-.894a.756.756 0 0 1 .065-.32.706.706 0 0 1 .163-.225 8.345 8.345 0 0 1 5.559-2.175h.027A8.3 8.3 0 0 1 41.3 34.82a.733.733 0 0 1 .165.23v.009a.746.746 0 0 1 .059.306v.887h-1.06v-.791a.616.616 0 0 0-.293-.513 8.262 8.262 0 0 0-4.444-1.429H35.7a8.286 8.286 0 0 0-4.435 1.4.614.614 0 0 0-.295.515v.789h-1.06zm-5.3 4.652a1.722 1.722 0 0 1 1.719-1.717h2.895a.3.3 0 1 0 0-.606h-.491v-.617a.223.223 0 0 1 .066-.158.22.22 0 0 1 .158-.065l2.963.011a.223.223 0 0 1 .222.223v.606h-1.4a.3.3 0 1 0 0 .606H45.1a1.722 1.722 0 0 1 1.719 1.717V48h-4.644v-1.281a.846.846 0 0 0-.842-.836h-1.1a.839.839 0 0 0-.834.836V48h-7.372v-1.281a.839.839 0 0 0-.834-.836h-1.1a.846.846 0 0 0-.842.836V48h-4.645zm17.771 10.6v.959H39.2v-.959zM40 49.973v-3.254a.233.233 0 0 1 .228-.23h1.1a.24.24 0 0 1 .236.23v3.254zm-7.775.606v.959h-3.177v-.959zm-2.373-.606v-3.254a.24.24 0 0 1 .236-.23h1.1a.233.233 0 0 1 .228.23v3.254zm-5.25-1.363h4.648v1.363h-.5a.316.316 0 0 0-.308.313v1.568a.3.3 0 0 0 .308.29h3.784a.289.289 0 0 0 .3-.29v-1.568a.309.309 0 0 0-.3-.313h-.508v-1.364H39.4v1.363h-.51a.309.309 0 0 0-.3.313v1.568a.289.289 0 0 0 .3.29h3.784a.3.3 0 0 0 .308-.29v-1.567a.316.316 0 0 0-.308-.313h-.5v-1.364h4.645v3.7a1.71 1.71 0 0 1-1.719 1.7H26.325a1.71 1.71 0 0 1-1.719-1.7z"
                                                transform="translate(-16.287)"
                                            />
                                            <path
                                                id="Path_969"
                                                data-name="Path 969"
                                                d="M250.3 212.6a.3.3 0 0 0 .3-.3v-1.8a.3.3 0 1 0-.606 0v1.8a.3.3 0 0 0 .306.3z"
                                                transform="translate(-230.878 -169.194)"
                                            />
                                            <path
                                                id="Path_970"
                                                data-name="Path 970"
                                                d="M287.3 212.6a.3.3 0 0 0 .3-.3v-1.8a.3.3 0 1 0-.606 0v1.8a.3.3 0 0 0 .306.3z"
                                                transform="translate(-266.01 -169.194)"
                                            />
                                            <path
                                                id="Path_971"
                                                data-name="Path 971"
                                                d="M325.3 212.6a.3.3 0 0 0 .3-.3v-1.8a.3.3 0 1 0-.606 0v1.8a.3.3 0 0 0 .306.3z"
                                                transform="translate(-302.092 -169.194)"
                                            />
                                            <path
                                                id="Path_972"
                                                data-name="Path 972"
                                                d="M362.3 212.6a.3.3 0 0 0 .3-.3v-1.8a.3.3 0 1 0-.606 0v1.8a.3.3 0 0 0 .306.3z"
                                                transform="translate(-337.224 -169.194)"
                                            />
                                            <path
                                                id="Path_973"
                                                data-name="Path 973"
                                                d="M212.3 212.6a.3.3 0 0 0 .3-.3v-1.8a.3.3 0 1 0-.606 0v1.8a.3.3 0 0 0 .306.3z"
                                                transform="translate(-194.796 -169.194)"
                                            />
                                            <path
                                                id="Path_974"
                                                data-name="Path 974"
                                                d="M175.3 212.6a.3.3 0 0 0 .3-.3v-1.8a.3.3 0 1 0-.606 0v1.8a.3.3 0 0 0 .306.3z"
                                                transform="translate(-159.664 -169.194)"
                                            />
                                            <path
                                                id="Path_975"
                                                data-name="Path 975"
                                                d="M137.3 212.6a.3.3 0 0 0 .3-.3v-1.8a.3.3 0 1 0-.606 0v1.8a.3.3 0 0 0 .306.3z"
                                                transform="translate(-123.583 -169.194)"
                                            />
                                            <path
                                                id="Path_976"
                                                data-name="Path 976"
                                                d="M53.3 208.33a.3.3 0 0 0-.3.3v4.5a.3.3 0 1 0 .606 0v-4.5a.3.3 0 0 0-.3-.3z"
                                                transform="translate(-43.823 -167.428)"
                                            />
                                            <path
                                                id="Path_977"
                                                data-name="Path 977"
                                                d="M53.3 185.26a.3.3 0 0 0-.3.3v.271a.3.3 0 1 0 .606 0v-.271a.3.3 0 0 0-.306-.3z"
                                                transform="translate(-43.823 -145.523)"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <div class="link-name">My Workspace</div>
                        </div>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'MyDesignIdeas',
                        }"
                        active-class="active"
                        class="menu"
                    >
                        <div class="menu-container">
                            <div class="link-icon">
                                <div v-if="$route.path === '/mudi/designideas'">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22.618"
                                        height="22.617"
                                    >
                                        <g
                                            id="noun_lamp_idea_home_3175048"
                                            data-name="noun_lamp idea home_3175048"
                                            transform="translate(.002)"
                                        >
                                            <g
                                                id="Group_871"
                                                data-name="Group 871"
                                                transform="translate(-.001)"
                                            >
                                                <path
                                                    id="Rectangle_1071"
                                                    data-name="Rectangle 1071"
                                                    style="fill:none"
                                                    d="M0 0h22.617v22.617H0z"
                                                />
                                            </g>
                                            <g
                                                id="Group_872"
                                                data-name="Group 872"
                                                transform="translate(-.002 .001)"
                                            >
                                                <path
                                                    id="Path_978"
                                                    data-name="Path 978"
                                                    class="cls-2"
                                                    d="M28.786 2.266 26.088.183a.95.95 0 0 0-1.115 0l-2.7 2.081a.661.661 0 0 0-.246.749.825.825 0 0 0 .8.521h.223v2.829a.707.707 0 0 0 .707.707H27.3a.707.707 0 0 0 .707-.707V3.536h.223a.825.825 0 0 0 .8-.521.661.661 0 0 0-.244-.749zm-4.314 4.1V4.6a.707.707 0 0 1 .707-.707h.707a.707.707 0 0 1 .707.707v1.763z"
                                                    transform="translate(-14.222 -.003)"
                                                />
                                                <path
                                                    id="Path_979"
                                                    data-name="Path 979"
                                                    class="cls-2"
                                                    d="m50.784 10.262-2.7-2.082a.947.947 0 0 0-1.115 0l-2.7 2.081a.661.661 0 0 0-.246.749.824.824 0 0 0 .8.521h.223v2.827a.707.707 0 0 0 .707.707H49.3a.708.708 0 0 0 .7-.706v-2.827h.223a.824.824 0 0 0 .8-.521.661.661 0 0 0-.239-.749zm-3.607 1.623h.707a.707.707 0 0 1 .707.707v1.768h-2.12v-1.767a.708.708 0 0 1 .705-.707z"
                                                    transform="translate(-28.446 -5.173)"
                                                />
                                                <path
                                                    id="Path_980"
                                                    data-name="Path 980"
                                                    class="cls-2"
                                                    d="M6.788 10.263 4.09 8.181a.948.948 0 0 0-1.115 0l-2.7 2.081a.661.661 0 0 0-.246.749.825.825 0 0 0 .8.521h.223v2.828a.708.708 0 0 0 .706.707H5.3a.707.707 0 0 0 .707-.707v-2.827h.223a.824.824 0 0 0 .8-.521.66.66 0 0 0-.242-.749zM3.18 11.886h.707a.707.707 0 0 1 .707.707v1.768H2.473v-1.768a.708.708 0 0 1 .707-.707z"
                                                    transform="translate(.002 -5.174)"
                                                />
                                                <path
                                                    id="Path_981"
                                                    data-name="Path 981"
                                                    class="cls-2"
                                                    d="M25.767 60H24v.707a.708.708 0 0 0 .707.707h4.241a.707.707 0 0 0 .707-.707V60h-3.888z"
                                                    transform="translate(-15.518 -38.798)"
                                                />
                                                <path
                                                    id="Path_982"
                                                    data-name="Path 982"
                                                    class="cls-2"
                                                    d="M25.152 23.659a5.966 5.966 0 0 0-4.439-1.652 6.071 6.071 0 0 0-5.7 5.591 6 6 0 0 0 2.378 5.2 1.057 1.057 0 0 1 .394.6l.3 1.32h5.836l.3-1.32a1.042 1.042 0 0 1 .388-.6 6 6 0 0 0 .534-9.144zm-1.317 7.529a.353.353 0 0 1-.353.353h-1.06v2.474h-.707v-2.473H20.3v2.474h-.707v-2.474h-1.06a.353.353 0 0 1-.353-.353v-.353a1.06 1.06 0 1 1 2.12 0h1.414a1.06 1.06 0 0 1 2.12 0z"
                                                    transform="translate(-9.699 -14.227)"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div v-else>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="mudiicons"
                                        width="22.618"
                                        height="22.617"
                                    >
                                        <defs></defs>
                                        <g
                                            id="noun_lamp_idea_home_3175087"
                                            data-name="noun_lamp idea home_3175087"
                                            transform="translate(.001)"
                                        >
                                            <g
                                                id="Group_873"
                                                data-name="Group 873"
                                                transform="translate(-.001)"
                                            >
                                                <path
                                                    id="Rectangle_1072"
                                                    data-name="Rectangle 1072"
                                                    style="fill:none"
                                                    d="M0 0h22.617v22.617H0z"
                                                />
                                            </g>
                                            <g
                                                id="Group_887"
                                                data-name="Group 887"
                                                transform="translate(-.001 .001)"
                                            >
                                                <g
                                                    id="Group_874"
                                                    data-name="Group 874"
                                                    transform="translate(5.302 7.773)"
                                                >
                                                    <path
                                                        id="Path_983"
                                                        data-name="Path 983"
                                                        d="M23.763 35.429h-5.509l-.468-2.029a1.058 1.058 0 0 0-.394-.6 6 6 0 0 1-2.378-5.2 6.07 6.07 0 0 1 5.7-5.591 6 6 0 0 1 3.9 10.8 1.044 1.044 0 0 0-.388.6zm-4.948-.707H23.2l.341-1.479a1.754 1.754 0 0 1 .651-1.005 5.3 5.3 0 0 0-3.445-9.525 5.356 5.356 0 0 0-5.028 4.932 5.293 5.293 0 0 0 2.1 4.589 1.765 1.765 0 0 1 .657 1.009z"
                                                        transform="translate(-15.001 -21.999)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_875"
                                                    data-name="Group 875"
                                                    transform="translate(8.482 20.496)"
                                                >
                                                    <path
                                                        id="Path_984"
                                                        data-name="Path 984"
                                                        d="M28.948 60.12h-4.241a.707.707 0 0 1-.707-.706V58h5.654v1.414a.707.707 0 0 1-.706.706zm-4.241-1.414v.707h4.241v-.707z"
                                                        transform="translate(-24 -58)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_876"
                                                    data-name="Group 876"
                                                    transform="translate(8.482 16.608)"
                                                >
                                                    <path
                                                        id="Path_985"
                                                        data-name="Path 985"
                                                        d="M29.3 47.707h-4.947a.353.353 0 0 1 0-.707H29.3a.353.353 0 0 1 0 .707z"
                                                        transform="translate(-24 -47)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_877"
                                                    data-name="Group 877"
                                                    transform="translate(9.895 16.608)"
                                                >
                                                    <path
                                                        id="Path_986"
                                                        data-name="Path 986"
                                                        d="M28.353 51.594a.353.353 0 0 1-.353-.353v-3.888a.353.353 0 0 1 .707 0v3.887a.353.353 0 0 1-.354.354z"
                                                        transform="translate(-28 -47)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_878"
                                                    data-name="Group 878"
                                                    transform="translate(12.016 16.608)"
                                                >
                                                    <path
                                                        id="Path_987"
                                                        data-name="Path 987"
                                                        d="M34.353 51.594a.353.353 0 0 1-.353-.353v-3.888a.353.353 0 0 1 .707 0v3.887a.353.353 0 0 1-.354.354z"
                                                        transform="translate(-34 -47)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_879"
                                                    data-name="Group 879"
                                                    transform="translate(8.482 15.548)"
                                                >
                                                    <path
                                                        id="Path_988"
                                                        data-name="Path 988"
                                                        d="M25.767 45.767a.353.353 0 0 1-.353-.353v-.354a.353.353 0 0 0-.707 0v.353a.353.353 0 0 1-.707 0v-.353a1.06 1.06 0 1 1 2.12 0v.353a.353.353 0 0 1-.353.354z"
                                                        transform="translate(-24 -44)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_880"
                                                    data-name="Group 880"
                                                    transform="translate(12.016 15.548)"
                                                >
                                                    <path
                                                        id="Path_989"
                                                        data-name="Path 989"
                                                        d="M35.767 45.767a.353.353 0 0 1-.353-.353v-.354a.353.353 0 0 0-.707 0v.353a.353.353 0 0 1-.707 0v-.353a1.06 1.06 0 0 1 2.12 0v.353a.353.353 0 0 1-.353.354z"
                                                        transform="translate(-34 -44)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_881"
                                                    data-name="Group 881"
                                                    transform="translate(10.249 3.886)"
                                                >
                                                    <path
                                                        id="Path_990"
                                                        data-name="Path 990"
                                                        d="M31.12 14.181H29v-2.474a.707.707 0 0 1 .707-.707h.707a.707.707 0 0 1 .707.707zm-1.414-.707h.707v-1.767h-.707z"
                                                        transform="translate(-29 -11)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_882"
                                                    data-name="Group 882"
                                                    transform="translate(7.774)"
                                                >
                                                    <path
                                                        id="Path_991"
                                                        data-name="Path 991"
                                                        d="M27.3 7.07h-3.536a.707.707 0 0 1-.707-.707V3.536h-.223a.825.825 0 0 1-.8-.521.66.66 0 0 1 .246-.749l2.7-2.081a.95.95 0 0 1 1.115 0l2.7 2.083a.661.661 0 0 1 .246.75.824.824 0 0 1-.8.521H28v2.824a.707.707 0 0 1-.7.707zm-4.569-4.262a.232.232 0 0 0 .1.021h.223a.707.707 0 0 1 .707.707v2.827H27.3V3.536a.707.707 0 0 1 .7-.707h.223a.234.234 0 0 0 .1-.02L25.658.744a.248.248 0 0 0-.256 0z"
                                                        transform="translate(-21.996 -.003)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_883"
                                                    data-name="Group 883"
                                                    transform="translate(18.023 6.712)"
                                                >
                                                    <path
                                                        id="Path_992"
                                                        data-name="Path 992"
                                                        d="M53.118 22.178H51V19.7a.707.707 0 0 1 .7-.7h.707a.707.707 0 0 1 .707.707zm-1.418-.707h.707V19.7H51.7z"
                                                        transform="translate(-50.998 -18.997)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_884"
                                                    data-name="Group 884"
                                                    transform="translate(15.548 2.826)"
                                                >
                                                    <path
                                                        id="Path_993"
                                                        data-name="Path 993"
                                                        d="M49.3 15.066h-3.537a.707.707 0 0 1-.707-.707v-2.827h-.223a.824.824 0 0 1-.8-.521.66.66 0 0 1 .246-.749l2.7-2.081a.947.947 0 0 1 1.115 0l2.7 2.082a.661.661 0 0 1 .246.75.825.825 0 0 1-.8.521H50v2.826a.708.708 0 0 1-.7.706zM44.73 10.8a.235.235 0 0 0 .1.021h.223a.707.707 0 0 1 .707.707v2.832h3.54v-2.828a.707.707 0 0 1 .7-.706h.223a.235.235 0 0 0 .1-.02l-2.667-2.065a.245.245 0 0 0-.256 0z"
                                                        transform="translate(-43.995 -7.999)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_885"
                                                    data-name="Group 885"
                                                    transform="translate(2.475 6.712)"
                                                >
                                                    <path
                                                        id="Path_994"
                                                        data-name="Path 994"
                                                        d="M9.122 22.178H7V19.7a.707.707 0 0 1 .709-.7h.707a.707.707 0 0 1 .707.707zm-1.414-.707h.707V19.7h-.706z"
                                                        transform="translate(-7.002 -18.997)"
                                                    />
                                                </g>
                                                <g
                                                    id="Group_886"
                                                    data-name="Group 886"
                                                    transform="translate(0 2.826)"
                                                >
                                                    <path
                                                        id="Path_995"
                                                        data-name="Path 995"
                                                        d="M5.3 15.066H1.767a.707.707 0 0 1-.707-.707v-2.827H.837a.824.824 0 0 1-.8-.521.66.66 0 0 1 .246-.749l2.7-2.081a.947.947 0 0 1 1.115 0l2.7 2.083a.661.661 0 0 1 .246.749.824.824 0 0 1-.8.521h-.236v2.826a.709.709 0 0 1-.708.706zM.734 10.8a.235.235 0 0 0 .1.021h.226a.707.707 0 0 1 .707.707v2.832H5.3v-2.828a.707.707 0 0 1 .707-.707h.223a.235.235 0 0 0 .1-.02L3.661 8.742a.245.245 0 0 0-.256 0z"
                                                        transform="translate(.001 -7.999)"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <div class="link-name">My Design Ideas</div>
                        </div>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'MyProfile',
                        }"
                        active-class="active"
                        class="menu"
                    >
                        <div class="menu-container">
                            <div class="link-icon">
                                <div v-if="$route.path === '/mudi/profile'">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20.838"
                                        height="22.617"
                                    >
                                        <defs></defs>
                                        <g
                                            id="noun_edit_3482431"
                                            transform="translate(-2.958 -31.781)"
                                        >
                                            <path
                                                id="Path_1000"
                                                data-name="Path 1000"
                                                class="cls-1"
                                                d="m256 432.679 2.355-.543-1.811-1.811z"
                                                transform="translate(-242.622 -378.281)"
                                            />
                                            <path
                                                id="Path_1001"
                                                data-name="Path 1001"
                                                class="cls-1"
                                                d="m58 219.941.39-1.688v-.012l.006-.021.007-.019.007-.017.01-.02.008-.015.012-.02.008-.013.014-.02.009-.012.016-.018.007-.007 5.25-5.25A8.062 8.062 0 0 0 56.457 208a7.791 7.791 0 0 0-5.932 2.748 10.925 10.925 0 0 0-2.482 7.028 20.787 20.787 0 0 0 8.414 2.246 14.934 14.934 0 0 0 1.543-.081z"
                                                transform="translate(-45.085 -167.094)"
                                            />
                                            <path
                                                id="Rectangle_1073"
                                                data-name="Rectangle 1073"
                                                class="cls-1"
                                                transform="rotate(-45 68.992 8.414)"
                                                d="M0 0h8.817v3.206H0z"
                                            />
                                            <ellipse
                                                id="Ellipse_339"
                                                data-name="Ellipse 339"
                                                class="cls-1"
                                                cx="3.815"
                                                cy="4.197"
                                                rx="3.815"
                                                ry="4.197"
                                                transform="translate(7.67 31.781)"
                                            />
                                            <path
                                                id="Path_1002"
                                                data-name="Path 1002"
                                                class="cls-1"
                                                d="M412.11 270.93a1.6 1.6 0 0 0-2.737-1.133l2.267 2.267a1.592 1.592 0 0 0 .47-1.134z"
                                                transform="translate(-388.314 -225.349)"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <div v-else>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="mudiicons"
                                        width="21.109"
                                        height="22.617"
                                    >
                                        <path
                                            data-name="Path 999"
                                            d="M.2 18.801a20.238 20.238 0 0 0 8.47 2.308c.415 0 .84-.019 1.269-.057l-.254 1.1a.377.377 0 0 0 .452.452l3.465-.8h.009l.022-.006.014-.005.02-.008.015-.007.017-.009.017-.01.014-.009.017-.013.012-.009.017-.015.007-.006 6.4-6.4.267-.267a2.263 2.263 0 1 0-3.2-3.2l-.266.266-.9.9a9.019 9.019 0 0 0-2.921-3.341 7.993 7.993 0 0 0-2.446-1.11 4.523 4.523 0 1 0-4.1 0 8.182 8.182 0 0 0-4.1 2.581A11.1 11.1 0 0 0 0 18.471a.377.377 0 0 0 .2.331zm10.358 2.936.511-2.215 1.7 1.7zm7.227-9.359a1.508 1.508 0 1 1 2.128 2.131zm-.533.533 2.132 2.132-5.868 5.866-2.132-2.132zM4.9 4.524a3.769 3.769 0 1 1 3.769 3.769A3.769 3.769 0 0 1 4.9 4.524zm-1.81 7.108a7.328 7.328 0 0 1 5.58-2.585 7.583 7.583 0 0 1 6.853 4.523l-4.938 4.939-.006.007-.015.017-.009.011-.014.018-.008.012-.011.019-.007.014-.009.019-.007.016-.006.018-.006.02v.011l-.366 1.588a14.044 14.044 0 0 1-1.448.077 19.551 19.551 0 0 1-7.914-2.113 10.276 10.276 0 0 1 2.331-6.611z"
                                        />
                                    </svg>
                                </div>
                            </div>

                            <div class="link-name">My Profile</div>
                        </div>
                    </router-link>
                </ul>
            </div>
            <div class="main-content">
                <router-view
                    :profileData="profileData"
                    :projectData="projectData"
                    :draft="draft"
                    :url="url"
                    :isPQ="isPQ"
                    :isSQ="isSQ"
                    :requiredDomain="requiredDomain"
                    @updateData="updateUserData"
                />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import MudiPageModule from 'store/modules/MudiPage'
import Footer from 'componentsv2/Footer'

export default {
    name: 'MudiPage',
    components: { Footer },
    data() {
        return {
            url:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,
        }
    },
    props: {
        requiredDomain: Boolean,
    },
    mixins: [RegisterStoreModule],
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Mudi',
        bodyAttrs: {
            class: ['mudi-page-body'],
        },
    },
    computed: {
        ...mapState({
            profileData: state => state.MudiPageModule.profileData,
            projectData: state => state.MudiPageModule.projectData,
            draft: state => state.MudiPageModule.draft,
            isSQ: state => state.AppModule.isSQ,
            isPQ: state => state.AppModule.isPQ,
        }),
    },
    created() {
        this.registerStoreModule('MudiPageModule', MudiPageModule)
        this.fetchUserData()
        this.fetchProject()
        //this.fetchDraft()
    },
    destroyed() {},
    methods: {
        ...mapActions({
            fetchUserData: 'fetchUserData',
            fetchProject: 'fetchProject',
        }),
        updateUserData() {
            this.fetchUserData()
            //this.fetchProject()
        },
    },
}
</script>

<style lang="scss">
@import './MudiPage.scss';
</style>
